<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-else>
      <h5 class="text-center text-primary">Map Teaching Session</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary"> Step 2: Map each student to a teacher</strong>
            <b-row>
              <b-container v-if="loadingMappingDetails">
                <div class="text-center">
                  <b-spinner label="Loading..." variant="warning"></b-spinner>
                </div>
              </b-container>
              <b-col v-if="!loadingMappingDetails">
                <strong>Responded students and preference</strong>
                <b-list-group>
                  <b-list-group-item 
                    v-for="item in studentsAttendance" 
                    :key="item.email" 
                    :variant="item.preference.includes('absent') ? 'light' : ((typeof(selectedTeachers[item.user_id]) ==='undefined' || selectedTeachers[item.user_id] === null) ? 'warning' : 'default')">
                    <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                    {{item.label}}
                    <div class="text-right">{{item.notes}}</div>
                    <div class="text-right" v-if="item.preference.includes('absent')">Absent</div>
                    <div class="text-right" v-else>Preference: {{ getPreferenceString(item.preference) }}</div>
                    <div><strong>Teached by</strong></div>
                    <b-form-select v-model="selectedTeachers[item.user_id]" :options="teachersOptions" class="mb-3" :disabled="item.preference.includes('absent')"/>
                    <div><strong>Teaching method</strong></div>
                    <b-form-select v-model="selectedTeachingMethod[item.user_id]" :options="teachingMethodOptions" class="mb-3" :disabled="item.preference.includes('absent')"/>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col v-if="!loadingMappingDetails">
                <strong>Responded teachers and preference</strong>
                <b-list-group>
                  <b-list-group-item 
                    v-for="item in teachersAttendance" 
                    :key="item.email"
                    :variant="item.preference.includes('absent') ? 'light' : 'default'">
                    <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                    {{generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}} 
                    <div class="text-right">{{item.notes}}</div>
                    <div class="text-right" v-if="item.preference.includes('absent')">Absent</div>
                    <div class="text-right" v-else>Preference: {{ getPreferenceString(item.preference) }}</div>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="!loadingMappingDetails">
                <div class="mt-3" />
                <b-form @submit.prevent="onSubmit">
                  <b-form-group id="input-group-1" label="Remarks" label-for="input-1">
                    <b-form-textarea
                      id="input-1"
                      type="text"
                      v-model="remarks"
                      placeholder="Enter remarks for the mapping"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-button type="submit" variant="primary" :disabled="this.date == '' || !this.dateWithSessionOptions.includes(this.date)">Submit mapping</b-button>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
      <b-alert 
        :show="dismissCountDown" 
        dismissible 
        variant="success" 
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
        >
        {{ alertMessage }}
      </b-alert>
      <b-alert
        :show="dismissErrCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissErrCountDown=0"
        @dismiss-count-down="countDownErrChanged"
      >
        {{ alertErrMessage }}
      </b-alert>
    </span>

    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';
  import AttendanceStudentService from '@/services/AttendanceStudentService.js';
  import AttendanceService from '@/services/AttendanceService.js';
  import MapTeachingSessionService from '@/services/MapTeachingSessionService.js';
  import UserService from '@/services/UserService.js';

  export default {
    name: 'jadwal',
    title: "TPA Gembira - Session Management",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        loading: false,
        loadingMappingDetails: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        teachersOptions: [],
        teachingMethodOptions: [{ value: "online", text: "Online learning (skype/whatsapp session)"}, { value: "classroom", text: "Offline (classroom meeting)"}, { value: "video", text: "Offline, submit video records"}],
        selectedTeachers: [],
        selectedTeachingMethod: [],
        teachersAvailableID: [],
        teachersAttendance: null,
        studentsAttendance: null,
        mappingStudents2Teacher: null,
        remarks: "",
        date:""
      }
    },
    created(){
      this.init();
    },
    methods: {
      getPreferenceString(arrPreference){
        let str = "";
        if (arrPreference.includes("video")){
          str += "Offline"
        }
        if (arrPreference.includes("classroom")){
          str += "Classroom"
        }
        if (arrPreference.length > 1){
          str += ", "
        }
        if (arrPreference.includes("online")){
          str += "Online"
        }
        return str
      },
      dateClass(ymd, date) {
        if (this.dateWithSessionOptions.includes(date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2))){
          return 'table-info';
        } else {
          return '';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      async onContext(ctx) {
        if (ctx.selectedYMD !== null && ctx.selectedYMD !== "" && this.date !== ctx.selectedYMD){
          this.loadingMappingDetails = true;

          this.context = ctx;
          this.date = ctx.selectedYMD;
          this.remarks = "";

          // get all accounts who can attend
          this.teachersAttendance = await AttendanceService.getAttendanceOnADate(ctx.selectedYMD);
          this.studentsAttendance = this.generateNameLabels(await AttendanceStudentService.getAttendanceOnADate(ctx.selectedYMD));
          // attendance may not be accurate, only used to suggest if there are default option

          let mapUserID2Notes = [];
          let listUserIDs = [];

          this.teachersAvailableID = [];
          this.teachersOptions = [];
          this.selectedTeachers = [];
          this.selectedTeachingMethod = [];
          for (const teacherEntry of this.teachersAttendance.entries()) {
            if (!teacherEntry[1].preference.includes("absent")){
              this.teachersAvailableID.push(teacherEntry[1].user_id);
              this.teachersOptions.push({ 
                "text": this.generateNameLabel(teacherEntry[1].given_name, teacherEntry[1].family_name, teacherEntry[1].nickname, teacherEntry[1].email) + " (pref: " + this.getPreferenceString(teacherEntry[1].preference) + ")",
                "value": teacherEntry[1].user_id
              });
            }
            listUserIDs.push(teacherEntry[1].user_id);
            mapUserID2Notes[teacherEntry[1].user_id] = listUserIDs.length - 1;
          }
          this.teachersOptions.push({ 'value': null, 'text': 'Not yet mapped'});

          // get list of all students user id
          for (const studentEntry of this.studentsAttendance.entries()) {
            listUserIDs.push(studentEntry[1].user_id);
            mapUserID2Notes[studentEntry[1].user_id] = listUserIDs.length - 1;
          }

          // enrich teacher and student attendance with notes
          let listOfNotes = await UserService.getUserNotes(listUserIDs);
          for (let i=0; i < this.teachersAttendance.length; i++){
            const notesIndex = mapUserID2Notes[this.teachersAttendance[i].user_id];
            const notesObject = listOfNotes[notesIndex];
            if (notesObject === null) {
              this.teachersAttendance[i].notes = "";
              continue;
            }
            const notes = notesObject.notes;
            if (null === notes) {
              this.teachersAttendance[i].notes = "";
            } else {
              this.teachersAttendance[i].notes = notes;
            }
          }
          for (let i=0; i < this.studentsAttendance.length; i++){
            const notesIndex = mapUserID2Notes[this.studentsAttendance[i].user_id];
            const notesObject = listOfNotes[notesIndex];
            if (notesObject === null) {
              this.studentsAttendance[i].notes = "";
              continue;
            }
            const notes = notesObject.notes;
            if (null === notes){
              this.studentsAttendance[i].notes = "";
            } else {
              this.studentsAttendance[i].notes = notes;
            }
          }

          // get from database if such things exist (only for initial)
          const mappingObject = await MapTeachingSessionService.getMapping(ctx.selectedYMD);
          if (typeof(mappingObject)!=='undefined'){
            if (typeof(mappingObject.mapping) !== 'undefined'){
              var mappingStudentTeacherHelper = []; 
              var mappingStudentTeachingMethodHelper = [];
              mappingObject.mapping.forEach((item) => {
                mappingStudentTeacherHelper[item.user_id_student] = item.user_id_teacher;
                mappingStudentTeachingMethodHelper[item.user_id_student] = item.teachingMethod;
              });
              for (const studentEntry of this.studentsAttendance.entries()){
                let teacherID = mappingStudentTeacherHelper[studentEntry[1].user_id];
                if (typeof(teacherID) !== 'undefined' && this.teachersAvailableID.includes(teacherID)){
                  this.selectedTeachers[studentEntry[1].user_id] = teacherID; 
                  this.selectedTeachingMethod[studentEntry[1].user_id] = mappingStudentTeachingMethodHelper[studentEntry[1].user_id];
                }
              }
            }
            this.remarks = mappingObject.remarks;
          }
          this.loadingMappingDetails = false;
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession()])
          .then(() => {
            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
              }
            })
          );
      },
      onSubmit(){
        if (this.date == "" || typeof(this.date) === "undefined"){
          this.showErrorConfirm("Please choose a date for a mapping")
        } else {
          if (!this.dateWithSessionOptions.includes(this.date)){
            this.showErrorConfirm("Please choose a date where a session is planned")
          } else {
            // transform mapping to array of key-value
            let selectedTeachersSubmission = [];
            for (var key in this.selectedTeachers){
              selectedTeachersSubmission.push({'user_id_student': key, 'user_id_teacher': this.selectedTeachers[key], 'teachingMethod': this.selectedTeachingMethod[key]});
            }
            let dataSubmission = { "date": this.date, "mapping": selectedTeachersSubmission, "remarks": this.remarks };
            MapTeachingSessionService.upsertMapping(this.date, dataSubmission);
            this.showSuccessConfirm("Mapping submitted successfully");
          }
        }
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>